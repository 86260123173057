<template>
	<div class="container">
		<div class="zhanwei20"></div>
		<van-list v-model="loading" :immediate-check="immediateCheck" @load="getRemindList" :finished="finished"
			finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载">
			<div class="news-list" v-for="(item,index) in list" :key="index" @click="remindIsRead(index)">
				<div class="flex-row align-top news-item">
					<img v-if="item.senderUserPic" :src="item.senderUserPic" class="item-head" />
					<img v-else src="../assets/mine/head_icon.png" class="item-head" />
					<div class="flex-column">
						<div class="flex-row ">
							<div class="item-user-name">{{item.senderUserName}}</div>
							<div class="tips" v-if="item.status == 1"></div>
						</div>
						<div class="item-con">{{item.content}}</div>
						<div class="item-date">{{item.createTime}}</div>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	Vue.use(Vant);

	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				list: [],
				error: false,
				immediateCheck: true
			}
		},
		computed: {
			isLogin() {
				return this.$store.state.user.token ? true : false
			}
		},
		methods: {
			getRemindList() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum)
				params.append('pageSize', this.pageSize)
				that.axios.post(config.requestUrl + '/front/forum/getRemindList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let list = that.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatCommentDate(createTime);
							let content = '';
							switch (newList[i].typeCode) {
								case 1:
								case 4:
									content = "评论了你：" + newList[i].summary;
									break;
								case 2:
								case 5:
									content = "回复了你：" + newList[i].summary;
									break;
								case 3:
									content = "点赞了你的话题：" + newList[i].summary;
									break;
								case 6:
									content = "点赞了你的提案：" + newList[i].summary;
									break;
								case 7:
									content = "评论了你的投票：" + newList[i].summary;
									break;
								case 8:
									content = "回复了你的投票：" + newList[i].summary;
									break;
								case 9:
									content = "点赞了你的投票：" + newList[i].summary;
									break;
								case 10:
									content = "引用了你的评论：" + newList[i].summary;
									break;
								case 11:
									content = "回复了你的评论：" + newList[i].summary;
									break;
								case 12:
									content = "点赞了你的评论：" + newList[i].summary;
									break;
								case 13:
									content = "引用了你的回复：" + newList[i].summary;
									break;
								case 14:
									content = "回复了你的回复：" + newList[i].summary;
									break;
								case 15:
									content = "点赞了你的回复：" + newList[i].summary;
									break;
								case 16:
								case 17:
								case 18:
								case 19:
									content = newList[i].summary;
									break;
								default:
									content = '';
									break;
							}
							newList[i].content = content;
						}

						if (newList.length < that.pageSize) that.finished = true;
						that.pageNum = that.pageNum + 1;
						that.loading = false
						that.list = list.concat(newList);
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
					that.loading = false;
				}, response => {
					that.error = true
					that.loading = false;
					that.$toast.fail('获取失败');
				})
			},
			remindIsRead(index) {
				const that = this;
				if (that.list[index].status == 1) {
					let params = new FormData()
					params.append('remindId', that.list[index].id)
					that.axios.post(config.requestUrl + '/front/forum/remindIsRead', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						that.list[index].status = 2;
						if (that.list[index].targetType == 1) {
							that.$router.replace('/topicdetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						} else if (that.list[index].targetType == 2) {
							that.$router.replace('/motiondetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						} else if (that.list[index].targetType == 3) {
							that.$router.replace('/initiatedetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						}
					}, response => {
						if (that.list[index].targetType == 1) {
							that.$router.replace('/topicdetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						} else if (that.list[index].targetType == 2) {
							that.$router.replace('/motiondetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						} else if (that.list[index].targetType == 3) {
							that.$router.replace('/initiatedetail?id=' + that.list[index].targetId + '&projectId=' +
								that.list[index].nftProjectId)
						}
					})
				} else {
					if (that.list[index].targetType == 1) {
						that.$router.replace('/topicdetail?id=' + that.list[index].targetId + '&projectId=' +
							that.list[index].nftProjectId)
					} else if (that.list[index].targetType == 2) {
						that.$router.replace('/motiondetail?id=' + that.list[index].targetId + '&projectId=' +
							that.list[index].nftProjectId)
					} else if (that.list[index].targetType == 3) {
						that.$router.replace('/initiatedetail?id=' + that.list[index].targetId + '&projectId=' +
							that.list[index].nftProjectId)
					}
				}

			},

		},
		mounted() {},
	}
</script>

<style scoped="scoped">
	body {
		background: #F5F5F5;
	}

	.container {
		width: 100%;
		/* height: 100%; */
		min-height: 100%;
		overflow: visible;
		box-sizing: content-box;
	}

	.zhanwei20 {
		height: 20px;
	}

	.news-list {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 0 auto;
		padding: 30px 23px 0;
		box-sizing: border-box;
	}

	.news-item {
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid #f5f5f5;
	}

	.item-head {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 21px;
	}

	.item-user-name {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
	}

	.item-con {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 34px;
		margin-top: 11px;
		overflow: hidden;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		width: 576px;
	}

	.item-date {
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
		margin-top: 14px;
	}

	.tips {
		width: 8px;
		height: 8px;
		border-radius: 10px;
		background: red;
		margin-left: 5px;
	}
</style>